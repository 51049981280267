import React, { useContext, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { prepareFilters } from "components/Card/utils";
import { getQuery } from "components/Filter/Filter.utils";
import FilterDropdownManager from "components/Filter/FilterDropdownManager";
import { getSortedRows } from "components/Table/CustomTable/utils/getSortingFunction";
import { TColumnType } from "components/Table/model";
import { downloadKpiAction } from "containers/dashboards/redux/actions";
import { DashboardFilterContext } from "containers/dashboards/subcategories/OptimetriksDashboardScreen";
import { useActions, useTranslations } from "hooks";
import { formatString } from "lang/utils";
import {
  DETAILED_ACTION_TYPE,
  DETAILED_OBJECT_CATEGORY,
  getDetailedActionName,
} from "model/application/ActionCode";
import { IKPI } from "model/entities/Dashboard";
import { downloadCsvFile, getCSVFromDataRows } from "utils/exportUtils";
import { handleColumnsSearch } from "utils/filterUtils";

import CustomCardTable from "../../Card/CustomCardTable";
import styles from "../styles";

const useStyles = makeStyles(styles as any);

interface ITableChartProps {
  chart: IKPI & {
    display_total_row?: boolean;
    tag: string;
    db_total_rows?: number;
  };
  fullWidth: boolean;
  thresholdTotalRow?: number;
  onDownloadExcel?: () => void;
  dashboardId: string;
}

const formatColumnTitle = (columnName: string): string => {
  return _.split(columnName, "_").join(" ");
};

export const formatColumn = (columnTypes: TColumnType[]): TColumnType[] => {
  return _.map(columnTypes, (ct) => ({
    ...ct,
    label: formatColumnTitle(ct.label),
  }));
};

export const KPI_TABLE_LIMIT = 5_000;
export const TableChart: React.FunctionComponent<ITableChartProps> = ({
  chart,
  thresholdTotalRow = KPI_TABLE_LIMIT,
  fullWidth,
  onDownloadExcel,

  dashboardId,
}) => {
  const classes = useStyles();
  const lang = useTranslations();
  const downloadAction = useActions(downloadKpiAction);
  const filtersContext = useContext(DashboardFilterContext);
  const [filterParams, setFilterParams] = useState({});

  //Initialisations
  const {
    db_total_rows: dbTotalRows,
    display_total_row: displayTotalRow,
    tag,
    title,
    data,
  } = chart;

  const columns = data.columns;
  const columnTypes = data.columnTypes;
  const rows = data.rows;

  const onChangeFilters = (filters: any) => {
    const filterParams = getQuery(filters, undefined);
    setFilterParams(filterParams);
  };

  const formattedColumnTypes = formatColumn(columnTypes);

  const formattedData = _.map(rows, (r) => {
    return columns.reduce(
      (acc: { [x: string]: any }, curr: string, idx: string | number) => {
        acc[curr] = r[idx];
        return acc;
      },
      {}
    );
  });

  const columnFilters = prepareFilters(
    columnTypes,
    formattedData,
    filterParams
  );

  const finalFilteredData = handleColumnsSearch({
    filterParams,
    items: formattedData,
    columns: columnTypes,
  });

  const handleFrontendDownloadCSV = (finalFilteredData: any[]) => {
    const header = formattedColumnTypes.map((ct) => ct.label);
    const sortedRows = getSortedRows({
      rows: finalFilteredData,
      columnTypes: formattedColumnTypes,
      sorting: {
        orderBy: "_displayed_name",
        order: "asc",
      },
    });
    const rows = sortedRows.map((row: any) =>
      formattedColumnTypes.map((ct) => row?.[ct.name])
    );
    const dataRows = getCSVFromDataRows([header, ...rows]);
    downloadCsvFile(dataRows, title ?? "Table chart");
  };

  const handleDownloadCSV = () => {
    // Local table filters cannot be used in the backend of the dashboard
    // because the available columns in the tables cannot be anticipated.

    const finalFilters = filtersContext?.outerFilter;
    if (dbTotalRows && dbTotalRows < KPI_TABLE_LIMIT) {
      handleFrontendDownloadCSV(finalFilteredData);
    } else {
      downloadAction(
        dashboardId,
        tag,
        getDetailedActionName(
          DETAILED_ACTION_TYPE.DOWNLOAD,
          DETAILED_OBJECT_CATEGORY.DASHBOARD
        ),
        finalFilters ?? {}
      );
    }
  };

  return (
    <div
      className={fullWidth ? classes.TableChartFullWidth : classes.TableChart}
    >
      <CustomCardTable
        isTableChart
        columnTypes={formattedColumnTypes}
        data={finalFilteredData}
        searchLabel={"Search"}
        actions={[]}
        noCardAround={true}
        bigSize={fullWidth}
        showSyntheticRow={displayTotalRow}
        onDownloadCSV={handleDownloadCSV}
        onDownloadExcel={onDownloadExcel}
        paginationDescription={
          dbTotalRows &&
          finalFilteredData.length < dbTotalRows &&
          dbTotalRows > thresholdTotalRow
            ? {
                description: formatString(
                  lang.components.table.pagination.description,
                  [finalFilteredData.length, dbTotalRows]
                ),
                tooltip: lang.components.table.pagination.tooltip,
              }
            : undefined
        }
        filterDropdownManager={
          <FilterDropdownManager
            filters={columnFilters}
            onChangeFilters={onChangeFilters}
            noBottomMargin
          />
        }
      />
    </div>
  );
};

export default TableChart;
