import { useEffect, useRef } from "react";

import { Box, Chip, Fade, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";

import { GreyState, Mustard90 } from "assets/colors";
import CustomSpinner from "components/Progress/CustomSpinner";
import { allListsSelector } from "containers/lists/redux/selectors";
import getItemName from "containers/lists/utils/getItemName";
import useTranslations from "hooks/useTranslations";
import { formatString } from "lang/utils";
import { IListItem } from "model/entities/ListItem";

import { IImageDetectionDataFE } from "../types";
import { getDetectionCategoryLabel } from "../utils";

const useStyles = makeStyles({
  product: {
    color: GreyState,
    fontSize: "16px",
    transition: "0.2s",
    height: "48px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: "8px",
    paddingRight: "8px",
    "&:hover": {
      backgroundColor: Mustard90,
    },
  },
  productImage: {
    width: "36px",
    height: "36px",
    marginRight: "16px", // espace entre l'image et le texte
    borderRadius: "4px", // coins arrondis
  },
  productSelected: {
    backgroundColor: Mustard90,
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingBottom: "12px",
    paddingLeft: "24px",
  },
  categoryTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "24px",
    paddingBottom: "24px",
    paddingLeft: "24px",
  },
  spinnerContainer: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  productText: {
    paddingLeft: "24px",
  },
  chipItem: {
    color: "#353347",
    background: "#eeebf3",
  },
  chipCategory: {
    color: "#fafbff",
    background: "#164c62",
  },
  productContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});

export interface IScanResultsTabProps {
  detections: IImageDetectionDataFE[];
  activeBoxIds: string[] | undefined;
  onClickScanResult?: (selectedBoxIds?: string[] | undefined) => void;
  onHoverScanResult?: (selectedBoxIds?: string[] | undefined) => void;
  onMount?: () => void; // used to enable detections when the tab is mounted
  onUnmount?: () => void; // used to disable detections when the tab is unmounted
  isLoadingScanResults?: boolean;
}
function ScanResultsTab({
  isLoadingScanResults,
  onHoverScanResult,
  onClickScanResult,
  detections,
  activeBoxIds,
  onMount,
  onUnmount,
}: IScanResultsTabProps) {
  const lang = useTranslations();
  const allLists = useSelector(allListsSelector);

  const selectedGroupRef = useRef<HTMLDivElement | null>(null);

  detections = detections.map((det) => {
    return {
      ...det,
      boxes: det.boxes.map((box) => {
        const detectionList = allLists.find(
          (l) => l.id === box?.product?.list_id
        );
        const categoriesOptions = detectionList?.schema?.find(
          (s) => s.column_tag === "_category"
        );
        const label = categoriesOptions?.options?.find(
          (o) => o.key === box?.product?._category
        )?.label;
        return {
          ...box,
          product: {
            ...box.product,
            categoryLabel: label,
          } as IListItem & { categoryLabel?: string },
        };
      }),
    };
  });
  const langKey =
    lang.containers.pictures.subCategories.pictures.createEditModal;
  const classes = useStyles();

  const detectionsGroupedByCategories = _.groupBy(
    detections,
    (detection) =>
      _.get(detection, "product._category") ??
      _.get(detection, "product.category") ??
      langKey.scanResultsTab.customMessage.noCategory
  );

  function handleClickScanResult(ids: string[]) {
    if (onClickScanResult) {
      onClickScanResult(ids);
    }
  }

  // enable detections when the tab is mounted
  useEffect(() => {
    if (onMount) {
      onMount();
    }
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedGroupRef?.current) {
      selectedGroupRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeBoxIds)]);

  return (
    <Box data-testid="scan-results">
      {!isLoadingScanResults && (
        <>
          <Box className={classes.title} data-testid="products-found">
            {formatString(
              detections.length !== 1
                ? langKey.scanResultsTab.customMessage.productsFound
                : langKey.scanResultsTab.customMessage.productFound,
              [detections.length]
            )}
          </Box>
          {_.map(_.keys(detectionsGroupedByCategories), (categoryKey) => {
            const detectionsInCategory =
              detectionsGroupedByCategories[categoryKey];
            const detectionsGroupedByProduct = _.groupBy(
              detectionsInCategory,
              (detection) => {
                return detection?.product?._id || detection.sku_id;
              }
            );
            return (
              <Box
                key={`cat-${categoryKey}`}
                data-testid={`cat-${categoryKey}`}
              >
                <Box className={classes.categoryTitle}>
                  {getDetectionCategoryLabel({
                    detection: detectionsInCategory[0],
                    lang,
                  })}
                </Box>
                <Box>
                  {_.map(
                    _.keys(detectionsGroupedByProduct),
                    (productId, index) => {
                      const productDetectionGroup =
                        detectionsGroupedByProduct[productId];

                      const firstDetection = productDetectionGroup[0];

                      const detectedProduct = firstDetection.product;

                      const productImageUrl = detectedProduct?._image_url?.url;

                      const label =
                        getItemName(detectedProduct as IListItem) ||
                        detectedProduct?.name ||
                        firstDetection.sku_id;

                      const allBoxIds = _.flatMap(
                        productDetectionGroup,
                        (pdg) => pdg.boxes.map((b) => b.id)
                      );
                      const selected =
                        _.size(_.intersection(activeBoxIds, allBoxIds)) > 0;
                      return (
                        <div
                          ref={selected ? selectedGroupRef : undefined}
                          key={firstDetection.sku_id + index}
                        >
                          <Fade in key={firstDetection.sku_id}>
                            <div
                              className={classNames(
                                classes.product,
                                selected && classes.productSelected,
                                classes.productContainer
                              )}
                              onClick={() => {
                                handleClickScanResult(allBoxIds);
                              }}
                              onMouseEnter={() => {
                                if (onHoverScanResult) {
                                  onHoverScanResult(allBoxIds);
                                }
                              }}
                              onMouseLeave={() => {
                                if (onHoverScanResult) {
                                  onHoverScanResult(undefined);
                                }
                              }}
                              data-testid={`scan-result-${firstDetection.sku_id}`}
                            >
                              {productImageUrl ? (
                                <img
                                  src={productImageUrl}
                                  alt={`Image of ${label}`}
                                  className={classes.productImage}
                                />
                              ) : (
                                <div className="material-icons-outlined">
                                  <span>image</span>
                                </div>
                              )}

                              <Box
                                className={classes.productText}
                                title={firstDetection.sku_id}
                                style={{ flexGrow: 1 }}
                              >
                                {label}
                              </Box>
                              <Chip
                                className={classes.chipItem}
                                key={firstDetection.sku_id}
                                label={_.size(allBoxIds)}
                                style={{ flexShrink: 0 }}
                              />
                            </div>
                          </Fade>
                        </div>
                      );
                    }
                  )}
                </Box>
              </Box>
            );
          })}
        </>
      )}
      {isLoadingScanResults && (
        <Box className={classes.spinnerContainer}>
          <CustomSpinner size={"50px"} />
        </Box>
      )}
    </Box>
  );
}

export default ScanResultsTab;
